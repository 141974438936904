import { AxiosResponse } from 'axios';
import { emperor } from '~/services';

export interface IEventReminderTemplate {
  uuid: string;
  subject: string;
  message: string;
  month: number;
}

export const getAllUpcomingEvents = async () => {
  return await emperor.get('groupevent/get-all-upcoming');
};

export const updateEventRemainder = async (uuid: string) => {
  return await emperor.patch('groupevent/remainder', { uuid });
};

export const getEventReminderTemplates = async (): Promise<
  AxiosResponse<IEventReminderTemplate[]>
> => {
  return await emperor.get('event/reminder-templates');
};

export const patchEventReminderTemplates = async (
  template: IEventReminderTemplate
): Promise<AxiosResponse<IEventReminderTemplate>> => {
  return await emperor.patch('event/reminder-template', template);
};
