import { createSlice } from '@reduxjs/toolkit';
import { Building, BuildingDashboardConfig } from '~/types/building';
import { GatheringType } from '~/types/gathering';
import _ from 'lodash';
import { Comment } from '~/types/comment';
import { Roles } from '~/enums/Roles';
import {
  ResidentScoreType,
  ResidentWithReviewInvitationType
} from '~/types/resident';
import { ReviewReceivedType, SearchReviewResultsType } from '~/types/reviewReceived';

export type BuildingStoreState = {
  buildings: Building[];
  gatherings: GatheringType[];
  comments: Comment[];
  positiveResidents: ResidentScoreType[];
  buildingDashboardConfig: BuildingDashboardConfig;
  residentsWithInvitation: ResidentWithReviewInvitationType[];
  reviewsReceived: ReviewReceivedType[];
  selectedBuilding: null | Building;
};

const buildingSlice = createSlice({
  name: 'building',
  initialState: {
    buildings: [] as Building[],
    gatherings: [] as GatheringType[],
    comments: [] as Comment[],
    positiveResidents: [] as ResidentScoreType[],
    buildingDashboardConfig: {} as BuildingDashboardConfig,
    residentsWithInvitation: [] as ResidentWithReviewInvitationType[],
    reviewsReceived: [] as ReviewReceivedType[],
    allReviews: [] as SearchReviewResultsType[],    
    selectedBuilding: null
  },
  reducers: {
    fetchBuildingsSuccess: (state, action) => {
      state.buildings = _.sortBy(action.payload, [
        (building: Building) => building.name.toLowerCase().trim()
      ]);
    },
    fetchGatheringsSuccess: (state, action) => {
      state.gatherings = action.payload;
    },
    fetchCommentsSuccess: (state, action) => {
      state.comments = action.payload;
    },
    fetchMoreCommentsSuccess: (state, action) => {
      state.comments = state.comments.concat(action.payload);
    },
    createCommentSuccess: (state, action) => {
      const { parent } = action.payload.data;

      if (!parent) state.comments = [action.payload.data, ...state.comments];
      else {
        const commentsCopy: Comment[] = [...state.comments];
        var parentIndex = commentsCopy.findIndex(
          (x: Comment) => x.uuid == parent.uuid
        );
        const commentCopy = commentsCopy[parentIndex];
        let childComments = [...commentCopy.children];
        childComments = [...childComments, action.payload.data];

        state.comments[parentIndex].children = state.comments[
          parentIndex
        ].children.concat(action.payload.data);
        // [...state.comments[parentIndex].children, action.payload];
      }
      if (action.payload.type === 'groupEvent') {
        const gathering = state.gatherings.filter(
          (g) => g.uuid === action.payload.data.groupEvent.uuid
        )[0];
        const index = state.gatherings.indexOf(gathering);
        const gatheringsCopy = state.gatherings;
        gatheringsCopy[index].countComments = gathering.countComments + 1;

        state.gatherings = gatheringsCopy;
      }
    },
    updateCommentSuccess: (state, action) => {
      const { parent, uuid } = action.payload;
      const commentsCopy: Comment[] = [...state.comments];

      if (parent) {
        var parentIndex = commentsCopy.findIndex((x) => x.uuid === parent.uuid);
        const commentCopy: any = commentsCopy[parentIndex];

        if (commentCopy?.children?.length) {
          const childComments = [...commentCopy.children];
          commentCopy.children = childComments.map((child: Comment) => {
            if (child?.uuid === uuid) {
              return action.payload;
            }
            return child;
          });
        } else if (commentsCopy.findIndex((x) => x.uuid === uuid) >= 0) {
          var commentIndex = commentsCopy.findIndex((x) => x.uuid == uuid);
          commentsCopy[commentIndex] = action.payload;
        }

        commentsCopy[parentIndex] = commentCopy;
      } else {
        var commentIndex = commentsCopy.findIndex((x) => x.uuid == uuid);
        commentsCopy[commentIndex] = action.payload;
      }
      state.comments = commentsCopy;
    },
    deleteGatheringSuccess: (state, action) => {
      state.gatherings = state.gatherings.filter(
        (g: GatheringType) => g.uuid !== action.payload.uuid
      );
    },
    createGatheringSuccess: (state, action) => {
      state.gatherings = [action.payload, ...state.gatherings];
    },
    setCommenterSuccess: (state, action) => {
      var index = state.buildings.findIndex(
        (x) => x.uuid == action.payload.building
      );
      if (
        action.payload.type === Roles.Superadmin ||
        action.payload.type === Roles.Vcm
      )
        state.buildings[index].VCMCommenter = action.payload.email;
      else state.buildings[index].PMCommenter = action.payload.email;
    },
    fetchPositiveResidentsSuccess: (state, action) => {
      state.positiveResidents = action.payload;
    },
    fetchMorePositiveResidentsSuccess: (state, action) => {
      state.positiveResidents = state.positiveResidents.concat(action.payload);
    },
    removeResidentFormResidentsTabSuccess: (state, action) => {
      state.positiveResidents = state.positiveResidents.filter(
        (r: ResidentScoreType) => r.user.uuid !== action.payload
      );
    },
    removeResidentFormAllResidentsTabSuccess: (state, action) => {
      state.allReviews = state.allReviews.filter(
        (r: SearchReviewResultsType) => r.uuid !== action.payload
      );
    },
    fetchBuildingDashboardConfigSuccess: (state, action) => {
      state.buildingDashboardConfig = action.payload.config;
    },
    updateDashboardConfigSuccess: (state, action) => {
      state.buildingDashboardConfig = action.payload.config;
    },
    removeResidentsFromInvitationSentTabSuccess: (state, action) => {
      state.residentsWithInvitation = state.residentsWithInvitation.filter(
        (r: ResidentWithReviewInvitationType) => r.user.uuid !== action.payload
      );
    },
    fetchResidentsWithReviewInvitationSuccess: (state, action) => {
      state.residentsWithInvitation = action.payload;
    },
    fetchMoreResidentsWithReviewInvitationSuccess: (state, action) => {
      state.residentsWithInvitation = state.residentsWithInvitation.concat(
        action.payload
      );
    },
    fetchReviewsReceivedSuccess: (state, action) => {
      state.reviewsReceived = action.payload;
    },
    fetchAllReviewsSuccess: (state, action) => {
      state.allReviews = action.payload;
      console.log(state.allReviews)
    },

    fetchMoreReviewsReceivedSuccess: (state, action) => {
      state.residentsWithInvitation = state.residentsWithInvitation.concat(
        action.payload
      );
    },
    updateReviewReceivedSuccess: (state, action) => {
      const copyReviews = [...state.reviewsReceived];
      const {
        reviewdate,
        reviewlink,
        reviewname,
        reviewnotes,
        reviewrating,
        reviewtext,
        uuid
      } = action.payload.data;
      const index = copyReviews.findIndex((review) => review.uuid === uuid);
      copyReviews[index] = {
        ...copyReviews[index],
        reviewdate,
        reviewlink,
        reviewname,
        reviewnotes,
        reviewrating,
        reviewtext
      };
      state.reviewsReceived = copyReviews;
    },
    // updateAllReviewsSuccess: (state, action) => {
    //   const copyReviews = [...state.reviewsReceived];
    //   const {
    //     reviewdate,
    //     reviewlink,
    //     reviewname,
    //     reviewnotes,
    //     reviewrating,
    //     reviewtext,
    //     uuid
    //   } = action.payload.data;
    //   const index = copyReviews.findIndex((review) => review.uuid === uuid);
    //   copyReviews[index] = {
    //     ...copyReviews[index],
    //     reviewdate,
    //     reviewlink,
    //     reviewname,
    //     reviewnotes,
    //     reviewrating,
    //     reviewtext
    //   };
    //   state.allReviews = copyReviews;
    // },    
    fetchBuildingDetails: (state) => {
      state.selectedBuilding = null;
    },
    fetchBuildingDetailsSuccess: (state, action) => {
      state.selectedBuilding = action.payload;
    }
  }
});
export const {
  fetchBuildingsSuccess,
  fetchGatheringsSuccess,
  fetchCommentsSuccess,
  fetchMoreCommentsSuccess,
  updateCommentSuccess,
  deleteGatheringSuccess,
  createGatheringSuccess,
  createCommentSuccess,
  setCommenterSuccess,
  fetchPositiveResidentsSuccess,
  fetchMorePositiveResidentsSuccess,
  removeResidentFormResidentsTabSuccess,
  removeResidentFormAllResidentsTabSuccess,
  fetchBuildingDashboardConfigSuccess,
  updateDashboardConfigSuccess,
  fetchResidentsWithReviewInvitationSuccess,
  fetchMoreResidentsWithReviewInvitationSuccess,
  removeResidentsFromInvitationSentTabSuccess,
  fetchReviewsReceivedSuccess,
  updateReviewReceivedSuccess,
  fetchAllReviewsSuccess,
  // updateAllReviewsSuccess,
  fetchMoreReviewsReceivedSuccess,
  fetchBuildingDetails,
  fetchBuildingDetailsSuccess
} = buildingSlice.actions;
export default buildingSlice.reducer;
