import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import routes from './routes/routes';
import { CustomRoute } from './types/customRoute';
import { QueryClient, QueryClientProvider } from 'react-query';
import Spinner from './components/Spinner/Spinner';

function App() {
  const queryClient = new QueryClient();

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Spinner />}>
          <Switch>
            {routes.map((route: CustomRoute, i: number) => (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={route.render}
              />
            ))}
          </Switch>
        </Suspense>
      </QueryClientProvider>
      <ToastContainer position={'bottom-right'} autoClose={2000} />
    </div>
  );
}

export default App;
