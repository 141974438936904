import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { CellProps } from 'react-table';
import {
  getAssignmentTagsRequest,
  getTagPropertiesRequest,
  patchAssignmentTagAttributes,
  postAssignmentTagBulkCSV,
  postAssignmentTagRequest,
  postChangeAssetAssignment
} from '~/api/assets';
import {
  AssignmentTagAccessor,
  IAssignmentTag,
  IAssignmentTagProperty
} from '~/types/assets';
import '../../components/ColumnGrowTable/ColumnGrowTable.css';
import { toast } from 'react-toastify';
import { ASSIGNMENT_TAGS, defaultTagAssignments } from '~/enums/Assets';
import { toTitleCase } from '~/utils/stringUtils';
import { FormInputConfig } from '~/types/residentManagement';
import { emailRegex, uriRegex } from '~/helpers/helpers';

export interface ITagAssignmentTableData {
  [AssignmentTagAccessor.tagName]?: IAssignmentTag[];
  [AssignmentTagAccessor.propertyName]?: IAssignmentTagProperty[];
  [AssignmentTagAccessor.action]?: any;
}

export const usePropertyAssignment = () => {
  //accessors have to be the keys
  const [tableData, setTableData] = useState<ITagAssignmentTableData>({
    [AssignmentTagAccessor.tagName]: undefined,
    [AssignmentTagAccessor.propertyName]: undefined,
    [AssignmentTagAccessor.action]: [0]
  });

  const allPropertiesWithTags = useRef<IAssignmentTagProperty[]>([]);

  const [selectedTagAndProperty, setSelectedTagAndProperty] = useState<{
    tag: IAssignmentTag;
    tagProperty: IAssignmentTagProperty | null;
  }>({
    tag: defaultTagAssignments[0],
    tagProperty: null
  });

  const newAssignmentSelectValue = useRef('');
  const inputRef = useRef<null | HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [uploadedFile, setUploadedFile] = useState<null | File>(null);

  const isFromAllTag =
    selectedTagAndProperty.tag.uuid === defaultTagAssignments[0].uuid;
  const isFromUassignedTag =
    selectedTagAndProperty.tag.uuid === defaultTagAssignments[1].uuid;

  const getAssignmentTags = async () => {
    try {
      setLoading(true);
      const res = await getAssignmentTagsRequest();

      setLoading(false);
      setTableData((prevState) => ({
        ...prevState,
        [AssignmentTagAccessor.tagName]: [
          ...res.data,
          ...[...defaultTagAssignments].reverse()
        ]
      }));
    } catch (e) {
      setLoading(false);
    }
  };

  const getTagProperties = async () => {
    try {
      setLoading(true);

      const res = await getTagPropertiesRequest();

      setLoading(false);

      allPropertiesWithTags.current = res.data;

      setTableData((prevState) => ({
        ...prevState,
        [AssignmentTagAccessor.propertyName]: res.data
      }));
    } catch (e) {
      setLoading(false);
    }
  };

  const fetchTagsAndProperties = () => {
    getAssignmentTags();
    getTagProperties();
  };

  useEffect(() => {
    //fetch all the tags and all the tag wise properties
    fetchTagsAndProperties();
  }, []);

  const onTableCell = (
    accessor: string,
    tableCellData: IAssignmentTag | IAssignmentTagProperty
  ): void => {
    newAssignmentSelectValue.current = '';

    switch (accessor) {
      case AssignmentTagAccessor.tagName: {
        setSelectedTagAndProperty({
          tag: tableCellData as IAssignmentTag,
          tagProperty: null
        });

        const filteredProperties =
          tableCellData[AssignmentTagAccessor.tagName] === ASSIGNMENT_TAGS.all
            ? allPropertiesWithTags.current
            : allPropertiesWithTags.current?.filter(
                (property: IAssignmentTagProperty) => {
                  if (
                    tableCellData[AssignmentTagAccessor.tagName] ===
                    ASSIGNMENT_TAGS.unassigned
                  ) {
                    return !property.tagName;
                  }

                  return property.userUuid === tableCellData.uuid;
                }
              );

        setTableData((prevState) => ({
          ...prevState,
          [AssignmentTagAccessor.propertyName]: filteredProperties
        }));

        break;
      }
      case AssignmentTagAccessor.propertyName: {
        setSelectedTagAndProperty((prevState) => ({
          ...prevState,
          tagProperty: tableCellData as IAssignmentTagProperty
        }));

        break;
      }
    }
  };

  const onNewAssignmentSelection = (e: React.FormEvent<HTMLSelectElement>) => {
    newAssignmentSelectValue.current = e.currentTarget.value;
  };

  const postAssignmentChangeAndFetchData = async (
    newAssignmentUserUuid?: string,
    oldTagUserUuid?: string
  ) => {
    await postChangeAssetAssignment(
      selectedTagAndProperty.tagProperty?.uuid as string,
      newAssignmentUserUuid,
      oldTagUserUuid
    );

    getTagProperties().then(() => {
      setSelectedTagAndProperty((prevState) => ({
        ...prevState,
        tagProperty: null
      }));

      if (isFromAllTag) {
        return;
      }

      const newFilteredProperties = allPropertiesWithTags.current?.filter(
        (property: IAssignmentTagProperty) => {
          if (isFromUassignedTag) {
            return !property.tagName;
          }

          return property.userUuid === selectedTagAndProperty.tag.uuid;
        }
      );

      setTableData((prevState) => ({
        ...prevState,
        [AssignmentTagAccessor.propertyName]: newFilteredProperties
      }));
    });
  };

  const onChangeAssignmentBtn = async () => {
    if (
      !newAssignmentSelectValue.current ||
      selectedTagAndProperty.tag.uuid === newAssignmentSelectValue.current
    ) {
      toast('Selected tag is same as already assigned tag');

      return;
    }

    try {
      const isToAllTag =
        newAssignmentSelectValue.current === defaultTagAssignments[0].uuid;
      const isToUassignedTag =
        newAssignmentSelectValue.current === defaultTagAssignments[1].uuid;

      if (
        (isFromAllTag && isToUassignedTag) ||
        (isFromUassignedTag && isToAllTag)
      ) {
        toast('Please assign to valid vcm tag');

        return;
      }

      setLoading(true);

      if (isToAllTag || isToUassignedTag) {
        await postAssignmentChangeAndFetchData(
          undefined,
          selectedTagAndProperty.tagProperty?.userUuid
        );

        return;
      }

      await postAssignmentChangeAndFetchData(newAssignmentSelectValue.current);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onDeleteAssignment = async () => {
    try {
      setLoading(true);

      await postAssignmentChangeAndFetchData(
        undefined,
        selectedTagAndProperty.tagProperty?.userUuid
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onAddTag = () => {
    setShowModal(true);
  };

  const onCreateTag = async () => {
    const newTagValue = inputRef?.current?.value;
    if (!newTagValue) {
      toast('Empty Tag field');

      return;
    }

    const tagPresent = tableData[AssignmentTagAccessor.tagName]?.find(
      (tag) =>
        tag[AssignmentTagAccessor.tagName].toLowerCase() ===
        newTagValue?.toLowerCase()
    );

    if (tagPresent) {
      toast('Tag already present');

      return;
    }

    try {
      setLoading(true);

      await postAssignmentTagRequest(newTagValue);
      getAssignmentTags();

      closeModal();
      setLoading(false);
    } catch (e) {
      toast(e.message || 'please try again adding new tag');
    }
  };

  const columnConfig: any[] = useMemo(() => {
    const showPropertyColumn = !!selectedTagAndProperty.tag;
    const showAction = !!selectedTagAndProperty.tagProperty;

    return [
      {
        Header: 'TAG',
        accessor: AssignmentTagAccessor.tagName,
        Cell: (cell: CellProps<IAssignmentTag>) => {
          const isSelected =
            selectedTagAndProperty.tag[AssignmentTagAccessor.tagName] ===
            cell.value;

          return (
            <div
              onClick={() => {
                onTableCell(AssignmentTagAccessor.tagName, cell.row.original);
              }}
              className={`default-text ${
                isSelected ? 'selected-tab-cell' : ''
              }`}
            >
              {toTitleCase(cell.value)}
            </div>
          );
        },
        sibling: (
          <button
            className='btn-standard btn-primary weight-500'
            onClick={onAddTag}
          >
            Add Tag
          </button>
        )
      },
      ...(showPropertyColumn
        ? [
            {
              Header: 'PROPERTIES',
              accessor: AssignmentTagAccessor.propertyName,
              Cell: (cell: CellProps<IAssignmentTagProperty>) => {
                const isSelected =
                  selectedTagAndProperty.tagProperty?.[
                    AssignmentTagAccessor.propertyName
                  ] === cell.value;

                return (
                  <div
                    onClick={() =>
                      onTableCell(
                        AssignmentTagAccessor.propertyName,
                        cell.row.original
                      )
                    }
                    className={`default-text ${
                      isSelected ? 'selected-tab-cell' : ''
                    }`}
                  >
                    {cell.value}
                  </div>
                );
              }
            }
          ]
        : []),
      ...(showAction
        ? [
            {
              Header: 'ACTION',
              accessor: AssignmentTagAccessor.action,
              Cell: () => {
                return (
                  <div className='change-assignment-container'>
                    {!(isFromAllTag || isFromUassignedTag) && (
                      <button
                        className='btn-standard btn-primary weight-500'
                        onClick={onDeleteAssignment}
                      >
                        Delete Assignment
                      </button>
                    )}
                    <div className='select-container'>
                      <select
                        onChange={onNewAssignmentSelection}
                        name='building'
                        className='height-50'
                        defaultValue={
                          selectedTagAndProperty.tagProperty?.userUuid ||
                          selectedTagAndProperty.tag.uuid
                        }
                      >
                        {tableData?.[AssignmentTagAccessor.tagName]?.map(
                          (tag: IAssignmentTag) => {
                            return (
                              <option value={tag.uuid} key={tag.uuid}>
                                {tag[AssignmentTagAccessor.tagName]}
                              </option>
                            );
                          }
                        )}
                      </select>
                      <button
                        className='btn-standard btn-primary weight-500'
                        onClick={onChangeAssignmentBtn}
                      >
                        Change Assignment
                      </button>
                    </div>
                  </div>
                );
              }
            }
          ]
        : [])
    ];
  }, [selectedTagAndProperty, onChangeAssignmentBtn]);

  const showAttributonForm = !(
    selectedTagAndProperty.tag[AssignmentTagAccessor.tagName] ===
      ASSIGNMENT_TAGS.all ||
    selectedTagAndProperty.tag[AssignmentTagAccessor.tagName] ===
      ASSIGNMENT_TAGS.unassigned
  );

  const onUploadAssignmentCSV = (event: ChangeEvent<HTMLInputElement>) => {
    var file = event?.target?.files?.[0];

    if (file) {
      setUploadedFile(file);
    }
  };

  const onSubmitAssignmentCSV = async () => {
    if (!uploadedFile) {
      toast('No file uploaded');
      return;
    }

    const formData = new FormData();
    formData.append('csvFile', uploadedFile);

    try {
      setLoading(true);
      await postAssignmentTagBulkCSV(formData);
      fetchTagsAndProperties();
      setSelectedTagAndProperty({
        tag: defaultTagAssignments[0],
        tagProperty: null
      });
      setUploadedFile(null);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const submitTagAttribute = async (attributes: IAssignmentTag) => {
    const { tagName, uuid, ...tagAttributes } = attributes;

    try {
      setLoading(true);

      await patchAssignmentTagAttributes(tagAttributes);

      getAssignmentTags();

      toast('Tag Attributes updated successfully');

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const tagAttributeformConfig: FormInputConfig[] = [
    {
      id: 'tagUuid',
      isRequired: false,
      displayNone: true
    },
    {
      label: 'Enter First Name',
      id: 'firstName',
      isRequired: false,
      placeholder: 'Enter Tag First Name'
    },
    {
      label: 'Enter Last Name',
      id: 'lastName',
      isRequired: false,
      placeholder: 'Enter Tag Last Name'
    },
    {
      label: 'Email',
      id: 'email',
      pattern: emailRegex(),
      patternErrorMsg: 'please enter a valid email',
      isRequired: false,
      placeholder: 'Enter Tag Email'
    },
    {
      label: 'Job Title',
      id: 'jobTitle',
      isRequired: false,
      placeholder: 'Enter Tag Job Title'
    },
    {
      label: 'Calender Link',
      id: 'calendarLink',
      pattern: uriRegex(),
      patternErrorMsg: 'please enter a valid link',
      isRequired: false,
      placeholder: 'Enter Tag Calender Link'
    }
  ];

  return {
    tableData,
    columnConfig,
    loading,
    showModal,
    closeModal,
    onCreateTag,
    inputRef,
    onUploadAssignmentCSV,
    onSubmitAssignmentCSV,
    showAttributonForm,
    submitTagAttribute,
    tagAttributeformConfig,
    tagAttributes: selectedTagAndProperty.tag,
    uploadedFile
  };
};
