import React, { useState, useEffect } from 'react';
import Spacer from '~/components/Spacer/Spacer';

type Props = {
  selectDate: (date: string) => void;
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsAbbrivated = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

const YearMonthPicker: React.FC<Props> = ({ selectDate }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // January is 1

  const [year, setYear] = useState<number>(currentYear);
  const [month, setMonth] = useState<string>(getMonthName(currentMonth));
  const [monthOptions, setMonthOptions] = useState<string[]>(
    getAvailableMonths(currentYear)
  );
  const [secondDate, setSecondDate] = useState<string>(
    getSecondDate(currentYear, currentMonth)
  );

  function getMonthName(monthNumber: number): string {
    return months[monthNumber - 1];
  }

  function getAvailableMonths(selectedYear: number): string[] {
    const currentMonth = new Date().getMonth() + 1;
    if (selectedYear === currentYear) {
      // Exclude the current month from the options
      return Array.from({ length: currentMonth - 1 }, (_, i) =>
        getMonthName(i + 1)
      );
    } else {
      return months;
    }
  }

  function getYearsOptions(): number[] {
    return Array.from({ length: 2 }, (_, i) => currentYear - i);
  }

  function getSecondDate(selectedYear: number, selectedMonth: number): string {
    const nextMonth = selectedMonth === 12 ? 1 : selectedMonth + 1;
    const nextYear = selectedMonth === 12 ? selectedYear + 1 : selectedYear;
    const secondDate = new Date(nextYear, nextMonth - 1, 3);
    return secondDate.toLocaleDateString('en-US');
  }

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = parseInt(e.target.value);
    setYear(selectedYear);
    setMonthOptions(getAvailableMonths(selectedYear));
    setSecondDate(getSecondDate(selectedYear, getMonthNumber(month)));
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = e.target.value;
    setMonth(selectedMonth);
    setSecondDate(getSecondDate(year, getMonthNumber(selectedMonth)));
  };

  useEffect(() => {
    setSecondDate(getSecondDate(year, getMonthNumber(month)));
    selectDate(getSecondDate(year, getMonthNumber(month)));
  }, [month, year]);

  const isFutureDate = (
    selectedYear: number,
    selectedMonth: number
  ): boolean => {
    const today = new Date();
    const selectedDate = new Date(selectedYear, selectedMonth - 1);
    return selectedDate > today;
  };

  const getMonthNumber = (monthName: string): number => {
    return months.indexOf(monthName) + 1;
  };

  return (
    <div>
      <h1>
        {month} {year}
      </h1>
      <Spacer height={40} />
      <h4>Year:</h4>
      <select id='year' value={year} onChange={handleYearChange}>
        {getYearsOptions().map((y) => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </select>
      <Spacer height={20} />
      <h4>Month:</h4>
      <select id='month' value={month} onChange={handleMonthChange}>
        {monthOptions.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </select>
      {isFutureDate(year, getMonthNumber(month)) && (
        <p style={{ color: 'red' }}>
          Reports are not yet generated for the month
        </p>
      )}
    </div>
  );
};

export default YearMonthPicker;
